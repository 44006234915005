.faq {
  padding: 20px 0;
}

.faq-heading {
  width: 100%;
  font-size: 30px;
  text-align: left;
  padding: 10px 0; /* Add padding if necessary */
}

.faq-question {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0px 0px;
  padding-left: 10px;
}

.faq-answer {
  padding: 10px 0;
  padding-left: 20px;
}

.faq-item h4 {
  margin-top: 10px !important;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.faq-item i {
  color: #60bb44;
  font-size: 24px;
  margin-right: 10px;
  padding: 0 !important;
}

.arrow-icon {
  font-size: 18px !important; /* Ensure a fixed size for arrow icon */
  margin-left: 10px;
  color: gray;
  transition: transform 3s ease-in-out;
}

.faq-question:hover .arrow-icon {
  color: #60bb44;
}

.faq-item {
  background-color: #f9f9f9; /* Light gray background for odd questions */
  padding: 10px;
  border-radius: 5px;
}

/* Additional styles */
.faq .faq-item {
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
}

.faq .faq-item i {
  color: #60bb44;
  font-size: 24px;
  float: left;
  line-height: 0;
  padding: 13px 0 0 0;
  margin: 0;
}

.faq .faq-item h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0 0 10px 32px;
  font-family: 'Poppins', sans-serif;
}

.faq .faq-item p {
  font-size: 15px;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .section-title h2,
  .faq-heading {
    text-align: center;
    font-size: 24px;
  }

  .faq-item h4 {
    font-size: 14px; /* Smaller text size for questions */
    line-height: 22px;
  }

  .faq-item p {
    font-size: 13px; /* Smaller text size for answers */
  }

  .arrow-icon {
    display: none; /* Fixed size for arrow icon */
  }
}
